<!--
 * @Author: Xuxinxiang
 * @Date: 2019-12-02 14:08:43
 * @LastEditTime: 2019-12-03 10:18:42
 -->
<template>
  <div id="feedback">
    <img src="../../assets/53.jpg" />
    <p class="title">留言反馈</p>
    <el-input
      type="textarea"
      placeholder="请输入具体内容"
      v-model="info"
      maxlength="300"
      show-word-limit
      resize="none"
      :rows="4"
    >
    </el-input>
    <el-input v-model="phone" placeholder="请输入手机号"></el-input>
    <p @click="submitFeedback" class="public-btn">提交</p>
  </div>
</template>

<script>
export default {
  data() {
    return {
      info: "",
      phone: ""
    };
  },
  methods: {
    submitFeedback() {
      if (this.info === "") {
        this.$message.error("请填写内容!");
        return;
      }
      if (this.phone === "") {
        this.$message.error("手机号码不能为空!");
        return;
      }
      if (!/^1[3456789]\d{9}$/.test(this.phone)) {
        this.$message.error("手机号码格式不正确!");
        return;
      }
      this.$http
        .post("/report/api/AddFeedback", {
          Info: this.info,
          Phone: this.phone
        })
        .then(response => {
          if (response.data.state) {
            this.$message.success(response.data.message);
            location.href = "/";
          } else {
            this.$message.error(response.data.message);
          }
        })
        .catch(error => {
          console.log(error);
        });
    }
  }
};
</script>
<style>
.el-input__inner {
  border: 1px solid #dadadb;
  background-color: #f8f9fd;
  margin-top: 20px;
}
</style>
<style lang="less" scoped>
#feedback {
  width: 1200px;
  margin: 18px auto;
  .title {
    font-size: 36px;
    color: #454545;
    text-align: center;
    padding: 38px 0;
  }
}
</style>
